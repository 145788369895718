<template>
	<div class="image-change">
		<div class="header acea-row row-between-wrapper" ref="header">
			<div class="black" @click="returnChange()">
				<img src="@assets/images/icon_black.png" alt="" />
			</div>
			<p>查看图片</p>
		</div>
	<viewer :images="images" class="image-de acea-row row-center-wrapper">
		<img :src="images">
	</viewer>
	</div>
</template>
<script type="text/javascript">
export default {
	name:'images',
	data(){
		return {
			images:'',
		}
	},
	created() {
		this.images = this.$route.query.path;
	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	}
}
</script>
<style type="text/css">
.image-change { padding-top:50px; height:100%; }
.image-change .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.image-change .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.image-change .header .black img {
  width: 100%;
  height: 100%;
}
.image-change .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}

.image-de { width:100%; height:100% }
.image-de img { width:100%; height:auto; }
</style>
